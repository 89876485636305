<template>
  <v-card class="elevation-1" :loading="formLoading" :disabled="formLoading">
    <v-card-text>
      <p class="mb-4 text-5">
        <v-icon>{{icon.info}}</v-icon> Select parameter then click generate.</p>
      <v-form>

        <!-- status -->
        <v-radio-group
          v-model="viewAs"
          row
          class="pt-0 mt-0"
        >
          <v-radio
            label="Table View"
            value="table-view"
          ></v-radio>
          <v-radio
            label="PDF"
            value="pdf"
          ></v-radio>
          <v-radio
            label="Excel"
            value="excel"
          ></v-radio>
        </v-radio-group>

        <!-- by -->
        <v-autocomplete
          v-model="groupBy"
          :items="['Employee', 'Department', 'Outlet']"
          outlined
          dense
          clearable
          label="Group By"
          :class="formErrorFields.groupByErrorMessage.length > 0 ? 'mb-3' : ''"
          :error-messages="formErrorFields.groupByErrorMessage"
          @keydown="formError().remove('groupBy', formErrorFields)"
        ></v-autocomplete>

        <!-- year -->
        <v-text-field
          v-model="year"
          label="Select year"
          outlined
          dense
          placeholder="Select year"
          autocomplete="off"
          type="number"
          min="2017"
          max="2030"
          step="1"
          :append-icon="icon.calendar"
          :class="formErrorFields.yearErrorMessage.length > 0 ? 'mb-3' : ''"
          :error-messages="formErrorFields.yearErrorMessage"
          @click="formError().remove('year', formErrorFields)"
        ></v-text-field>

        <v-btn
          color="primary"
          :loading="loading"
          @click="generate">
          Generate
        </v-btn>
        <v-btn
          outlined
          class="mx-2"
          @click="reset"
        >
          Reset
        </v-btn>
      </v-form>
    </v-card-text>

    <snackbar
      v-if="message.length > 0"
      :show="message.length > 0"
      :text="message"
      :color="messageStatus"
      @close="message=''"
    >
    </snackbar>
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiCalendarOutline, mdiInformationOutline } from '@mdi/js'
import snackbar from '@/components/SnackBar.vue'
import formError from '@/composables/formErrorHandler/formError'
import blob from '@/composables/blob'
import fnx from '@/functions/fn'

export default {
  components: {
    snackbar,
  },

  setup(props, { emit }) {
    const loading = ref(false)
    const icon = { calendar: mdiCalendarOutline, info: mdiInformationOutline }
    const year = ref('2021')
    const viewAs = ref('table-view')
    const groupBy = ref('Employee')
    const formLoading = ref(false)
    const formErrorFields = ref({
      fromDateErrorMessage: '',
      toDateErrorMessage: '',
      yearErrorMessage: '',
      groupByErrorMessage: '',
    })
    const message = ref('')
    const messageStatus = ref('')
    const error = ref('')
    const reportData = ref(null)

    const generate = async () => {
      const formData = {
        type: 'comparison',
        groupBy: groupBy.value,
        year: year.value,
        viewAs: viewAs.value,
      }

      if (viewAs.value === 'table-view') {
        emit('sendFormData', formData)
      } else {
        let mimetype = ''
        if (viewAs.value === 'pdf') {
          mimetype = 'application/pdf'
        } else {
          mimetype = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }

        await blob(`${process.env.VUE_APP_URI}/api/payroll/reports/13-month/comparison/${fnx.base64_encode(formData)}`, reportData, formLoading, error)
        if (error.value.data !== undefined) {
          message.value = error.value.data.message
          messageStatus.value = error.value.status
        } else {
          console.log(reportData.value)
          const url = window.URL.createObjectURL(new Blob([reportData.value], { type: mimetype }))
          window.open(url, '_blank')
        }
      }
    }

    const yearWasSelected = val => {
      year.value = val
    }

    const reset = () => {
      viewAs.value = 'table-view'
      groupBy.value = ''
      year.value = ''
    }

    return {
      yearWasSelected,
      year,
      viewAs,
      groupBy,
      icon,
      formLoading,
      reset,
      generate,
      loading,
      formErrorFields,
      formError,
      message,
      messageStatus,
    }
  },

  watch: {
    // data(e) {
    //   // console.log(e)
    // },
  },
}
</script>
